import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '66JfvYm8rkjHPqC6RraRnP',
  name: 'home',
  blocks: [
    {
      id: '14AFVEwFZUVVsaRE8XZ9ug',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel AU',
        slides: [
          {
            id: '6oRLOjo7GcLs6dxXe2ohYU',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4Zz8mDOU6zXlBeTix7zpqI/cbe700dc56436a79862a22d59baff627/DN_HP-Banners_2_desktop_US__1_.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3H6hQhNlfaoOhlEk6XTDrH/725153493f0ad004badc6b57bd1d8082/DN_HP-Banners_2_mobile_US.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '[AU] [HP] iPhone x DN',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/iphone/fe399560-2b82-4503-804b-7d41fbcc5d96',
            },
          },
          {
            id: '7gcEISAEShgP9QphG69kv2',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3EhKRDr5sStrxx3NNTPjdJ/e3ed77397350c1138508557b4c098bcc/AU_Merch-banner-web.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6kSdjiiu2qE6lue0P2WoC1/c81e57f30add7b3ebef482d9bd552282/AU_Merch-banner-mobile_1.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'AU - Student Discount',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/student-discount',
            },
          },
          {
            id: '4tmJwLo8QYBZjOBRYbc6sR',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/VkkAd2UFLBjlOrU1XlfSE/36c9d043fb897e3b6f8cacac24f18479/US_Refurbished_VS_Used_HP_Desktop_V3.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5s4WnZdFCW8cj8d2SRCt17/aeb60f22c8cf16bfbdd43db39cb72ca4/US_Refurbished_VS_Used_HP_Mobile_V3.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'AU - Refurbished vs. used',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/content/used-vs-refurbished',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '26XMZn1aJdJ4aub94clCJv',
      type: 'Categories',
      props: {
        technicalName: 'Top Sellers',
        title: 'Top Sellers',
        subtitle: '- Roll up, roll up!',
        categories: [
          {
            id: '22gO6DuTC7LBRyiSq8IwkO',
            title: 'MacBooks',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/9MX5iyCngiIXMgzDS29OV/3e8f96cc49d7f44867cc19c453ef8dd0/MacBook_Air__2022__M2.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/HGe7hQv5gWmrrG5kj0o5s/3c1c79ce623baa708332d98c9bc2e667/MacBook_Air__2022__M2.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'MacBook Air M2',
            },
            tagline: 'How do like the apples ?',
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/macbook/831925a6-4741-4dca-aef4-be15604cb0e8',
            },
          },
          {
            id: '6aUYzHdNXSf0XpfSYWCVh9',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5MlrK2Ty63e43nXJu92D3X/5a6ee13188d5fb27d64e6e5766051aef/iphone-13-pro-max-us.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 13 Pro Max',
            },
            tagline: 'Bring home a smarter phone.',
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/iphone/fe399560-2b82-4503-804b-7d41fbcc5d96',
            },
          },
          {
            id: '3SZkooBYpoVvGdjOjlDYiA',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5WKiEv6Ke3xmxePRq1aK7k/901f364a5eb761db1e412bcb401a6ee5/Galaxy_S20_FE_5G.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung Galaxy S20 FE 5G (Front)',
            },
            tagline: 'Intergalactic phones, at down to earth prices.       ',
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/galaxy-s-series/159bc59f-c46e-4503-81ba-03724fca7516',
            },
          },
          {
            id: 'ua6Fex8TtJ9H4VbpZMq6U',
            title: 'iPad',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4mw1VnREHuLh2fhAkwD1z4/1380aec2cb398ec930e947065f271f07/None_dae26af1-c478-409d-ae33-adc__1_.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro 2022',
            },
            tagline: "Those shows won't binge themselves, you know !",
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/ipad/1692e8f6-4671-4e3a-a99b-8b18841e6f35',
            },
          },
          {
            id: '1PnpbgMNzc7eCHNw7oyK6x',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3YMiDmVjmnXTkri7J7qWW0/18ba6ea23f8f34971d8374662fe50d3e/Apple_Watch_Series_8_-_Black.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7dxmoX1a6jzPgz7F2xtWzW/7bcd58197e6349b37c512733e7f93450/Apple_Watch_Series_8_-_Black.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'Apple Watch Series 8 - Black',
            },
            tagline: 'Time to upgrade that old Casio.',
            link: {
              href: 'https://www.backmarket.com.au/en-au/l/apple-watch/ce7db2e5-e878-47d8-99f4-f81f60383ead',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3GxtADv6Wt9UvHki9eetCB',
      type: 'ArticleCardsList',
      props: {
        title: 'The latest in refurbished tech',
        subtitle: '',
        articles: [
          {
            id: '6H6rOap6QR3lDhksTcBhmX',
            text: 'Thinking about getting a Samsung Galaxy S22 phone but not sure which to get of the three? Let our quick guide help you decide.',
            category: 'samsung',
            translatedCategory: 'Samsung',
            title:
              'Samsung Galaxy S22 vs S22 Plus vs S22 Ultra: Which One Should You Get?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/0a2c301af249ee9d19721cede2b24042-meta-526-276/cb115285ce0156214620eb528ccfe42e/s22-vs-s22-plus-vs-s22-ultra-mob-AU.jpg',
              width: 480,
              height: 265,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung Galaxy S22 vs S22 Plus vs S22 Ultra: Which One Should You Get?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/samsung/s22-vs-s22-plus-vs-s22-ultra',
            },
          },
          {
            id: 'LmlqHEKc2DuV6uhIuDwpY',
            text: 'Every year Apple releases a new iPhone making it more difficult trying to choose which iPhone to buy - let us help you with this handy guide!',
            category: 'iphone',
            translatedCategory: 'iPhone',
            title: 'Which iPhone should I buy?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/09aaaa96393892246db4dee7502db9d7-meta-526-276/d01b99ad08f74c9eb3d2138c47557eab/iphone-chargeur.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Which iPhone should I buy?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/iphone/which-iphone-is-best-suited-for-me',
            },
          },
          {
            id: '2KG52hNccOeEHERM5URDeH',
            text: "Struggling to decide on the perfect storage size for your iPhone 14/Plus/Pro/Pro Max? Discover our expert advice on what capacity iPhone you need, how much GB is ideal, and which storage is best for your iPhone. Make an informed choice with Back Market's help!",
            category: 'iphone',
            translatedCategory: 'iPhone',
            title:
              'Which storage capacity should you choose for your iPhone 14/Plus/Pro/Pro Max?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/618165c1f080a1447d2b64f69f98a0c7-meta-526-276/f454a08bd2f8df3a2575297936004c01/iPhone-14-storage-hero-mob-AU.jpg',
              width: 480,
              height: 265,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Which storage capacity should you choose for your iPhone 14/Plus/Pro/Pro Max?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/iphone/storage-iphone-14',
            },
          },
          {
            id: '4LS7HeSckmRrq99ZOclb9B',
            text: 'iPhone cameras are known for high quality photos and videos, but which iPhone has the best camera? Find out the best iPhone camera for you here.',
            category: 'iphone',
            translatedCategory: 'iPhone',
            title: 'Which iPhone Has the Best Camera?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/42cbb7267ff5e5820cc022429593b02c-meta-526-276/e02f6685f7d9c9cb68278fa69a575699/best-iphone-camera.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Which iPhone Has the Best Camera?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/iphone/best-iphone-for-photos',
            },
          },
          {
            id: '2MYO3ljUZM7gWHMGNu7oZ7',
            text: "Discover the best deals on iPhone 11, touted as the world's top smartphone of 2019. Explore how much an iPhone 11 costs today, find the cheapest iPhone 11 to buy outright in the UK, and get your hands on a refurbished iPhone 11 at unbeatable prices. Quality guaranteed!",
            category: 'iphone',
            translatedCategory: 'iPhone',
            title: 'iPhone 11 price: Where to buy a cheap iPhone 11?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/97be8b40e9dd70fd7b6b379ebc1af9b8-meta-526-276/53f924882923b44e338d8432a24536ed/iPhone_11_pro_max_preis-mob-AU.jpg',
              width: 480,
              height: 265,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 11 price: Where to buy a cheap iPhone 11?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/iphone/iphone-11-price',
            },
          },
          {
            id: '1jiYk376oGL5Bp2QEC5qO7',
            text: "Struggling to choose between Apple Watch Series 5 and 6? Dive into our comprehensive comparison and discover the key differences. Make an informed decision today! Perfect for 'Apple Watch 5 vs 6' seekers.",
            category: 'apple-watch',
            translatedCategory: 'Apple Watch',
            title: 'Apple Watch 5 vs 6: Which one should I choose?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5bc1a475c95d59cea8435e9eed946326-meta-526-276/5450b4caa8e7c60871865472eac23da5/Apple-watch-series-5-vs-6-mob-AU.jpg',
              width: 480,
              height: 265,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch 5 vs 6: Which one should I choose?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/apple-watch/apple-watch-5-vs-apple-watch-6',
            },
          },
          {
            id: '1Dzl7TmTkL5ImQEcAMKKMl',
            text: 'Wondering how much the iPhone 12 Pro costs and where to buy it for less? This article is for you.',
            category: 'iphone',
            translatedCategory: 'iPhone',
            title:
              'iPhone 12 Pro Max price: how much is it and should you buy it?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/177c2df5a3a291541a06302c09f28561-meta-526-276/960ec5dafbe29068359b48a4a1346d9d/iPhone_12_Pro_Max.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12 Pro Max price: how much is it and should you buy it?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/iphone/iphone-12-pro-max-price',
            },
          },
          {
            id: '3RlV00zAQyXzyW4sqUDqYk',
            text: 'Choosing between the Samsung S20 and S10? Read our S20 vs S10 comparison review to help you decide.',
            category: 'samsung',
            translatedCategory: 'Samsung',
            title:
              'Samsung S10 vs S20: Which will conquer your mind, heart and wallet?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/408bdaa65c3e3fe7c3151672b327bbe1-meta-526-276/18037588ebde7ee561fd339ce6a7dd45/Buying-Guide-s20-vs-s10.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung S10 vs S20: Which will conquer your mind, heart and wallet?',
            },
            link: {
              href: 'https://www.backmarket.com.au/en-au/c/samsung/galaxy-s20-vs-s10',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3D7KihEthNwyhYQ7FFglfH',
      type: 'Content',
      props: {
        title: 'Let them buy new. We’ll buy Back Market.',
        text: 'We get the same quality, and the same excitement, for less. Watch to see why.',
      },
      hiddenDevices: [],
    },
    {
      id: '3QiBzaG1CONhZl8j6xkdBQ',
      type: 'EmbeddedYoutubeVideo',
      props: {
        youtubeId: 'Q_GuTbz8Ymk',
      },
      hiddenDevices: [],
    },
    {
      id: '2oGqczXmOrHILIf4nwWbNQ',
      type: 'Faq',
      props: {
        title: 'What else?',
        subtitle: '3 questions people always ask',
        questions: [
          {
            id: '5s8UKrRggniSCRa0M2fNHx',
            title: 'How do I know my device won’t conk out in a month?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'From a pre-list screening process for all sellers to “mystery orders” placed by Back Market technical staff (under secret code names 🤫), we give 110% in making sure the tech we offer you is what we promise. Plus, every device comes with 30 days to change your mind and a 1-year warranty.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1JZ4A8wu80KL17UIEPf0lj',
            title: 'What’s the difference between renewed and new? ',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "On the outside, a renewed smartphone looks (and works) like new. But it’s what's on the inside that really counts. Renewed tech — smartphones, tablets, laptops, and computers — have way less environmental impact than brand new.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6AE0PHjjLmVhjKkIL1Nytb',
            title:
              'Was the T-800 in Terminator 2: Judgment Day (1991) technically “refurbished”?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Most likely. We’d need to hand Skynet over to our Innovation Lab before we say yes or no, but we have a hunch that it’s an “I’ll be back” scenario.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Back Market - your refurbished electronics (Super) Market',
  meta: {
    title: 'Your Refurbished (Super) Market',
    description:
      'Find the best deals on refurbished phones, laptops, and tech at Back Market. Up to 70% off when compared to new products. Free delivery, 1-year warranty, 30-day money back guarantee.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/0f639818c601250bfabb45bffe9516e9/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Your Refurbished (Super) Market',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home',
}

export default HOME_FALLBACK_DATA
